<template>
  <div class="flex-container">
    <el-row>
      <el-col :span="24">
        <el-statistic title="新闻总量" :value="output_count_total" />
      </el-col>
    </el-row>

    <el-progress style="padding-top: 20px; padding-bottom: 20px" :percentage="progress" :stroke-width="3" :show-text="false" />

    <div class="table-container">
      <el-table :data="list_news" style="width: 100%">
        <el-table-column prop="id" label="序号" :width="isMobile ? '' : 80"/>
        <el-table-column prop="timestamp" label="日期" :width="isMobile ? '' : 200" />
        <el-table-column label="内容">
          <template #default="{ row }">
            <div v-html="highlightKeywords(row.text)"></div>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script setup>
import { useTransition } from '@vueuse/core'
import {onMounted, ref} from "vue";
import {ElNotification} from "element-plus";
import api from "@/api";

const count_total = ref(0)
const output_count_total = useTransition(count_total, { duration: 1500 })
const list_news = ref([])
const totalSeconds = 30;
const progress = ref(100);
const isMobile = ref(false);

onMounted(() => {
  checkIsMobile()
  getData()
})

let timer = null;
const startTimer = () => {
  progress.value = 100; // 重置进度条
  timer = setInterval(() => {
    progress.value -= 100 / totalSeconds;
    if (progress.value <= 0) {
      clearInterval(timer);
      getData();
      startTimer(); // 重新启动计时器
    }
  }, 1000);
};
startTimer(); // 初始启动计时器

const checkIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

const getData = () => {
  api.post('/zhongyan', {
  })
    .then(response => {
      console.log(response.data)
      count_total.value = response.data.count_total
      list_news.value = response.data.news
    }).catch(err=>{
    ElNotification({
      message: err,
      type: 'error',
      duration: 10000,
    });
  })
}

const highlightKeywords = (text) => {
  const keywords = ['烟', '红塔', '红河', '玉溪', '重九'];
  let highlightedText = text;
  keywords.forEach(keyword => {
    const regex = new RegExp(`(${keyword})`, 'gi');
    highlightedText = highlightedText.replace(regex, '<span style="color:orangered; font-weight: bold">$1</span>');
  });
  return highlightedText;
};

</script>

<style scoped>

.flex-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px); /* 设置整个容器的高度为视口高度 */
}

.stats-row {
  flex: 0 0 auto; /* 统计信息部分不伸缩，保持原始大小 */
}

.table-container {
  flex: 1; /* 表格部分平分剩余空间 */
  height: 0; /* 防止溢出 */
  overflow: auto; /* 添加滚动条 */
}

</style>