<template>
  <div class="Hi">
    <h1 style="padding-bottom: 50px">股市行情</h1>
    <div class="button-container">
      <el-button @click="enterTrade('BTC')">BTC</el-button>
      <el-button @click="enterTrade('ETH')">ETH</el-button>
      <el-button @click="enterTrade('黄金')">黄金</el-button>

      <el-button @click="enterTrade('标普500')">标普500</el-button>
      <el-button @click="enterTrade('纳斯达克')">纳斯达克</el-button>
      <el-button @click="enterTrade('道琼斯')">道琼斯</el-button>

      <el-button @click="enterTrade('上证')">上证</el-button>
      <el-button @click="enterTrade('深证')">深证</el-button>
    </div>

    <div class="button-container">
      <el-button @click="enterTrade('SH601608')">中信重工</el-button>
      <el-button @click="enterTrade('SH601000')">唐山港</el-button>
      <el-button @click="enterTrade('SZ000166')">申万宏源</el-button>
      <el-button @click="enterTrade('SZ002232')">启明信息</el-button>
      <el-button @click="enterTrade('SZ002224')">三力士</el-button>
    </div>
  </div>
</template>

<script setup>

const enterTrade = (id) => {
  const target = `/stock/${id}`;
  window.open(target, '_blank');
}

</script>

<style scoped>

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hi {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
  min-width: 95vw;
}

.button-container {
  display: flex;
  flex-wrap: wrap; /* 允许元素在需要时换行 */
  gap: 15px; /* 设置按钮之间的间距 */
}

.el-button {
  margin-bottom: 20px; /* 为每个按钮底部添加间距 */
}

</style>