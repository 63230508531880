<template>
  <div class="flex-container">
    <el-row>
      <el-col :span="6">
        <el-statistic title="新闻总量" :value="output_count_total" />
      </el-col>
      <el-col :span="6">
        <el-statistic title="解析总量" :value="output_count_read_src_done">
        </el-statistic>
      </el-col>
      <el-col :span="6">
        <el-statistic title="未处理" :value="output_count_read_src_processing" />
      </el-col>
      <el-col :span="6">
        <el-statistic title="已超时" :value="output_count_read_src_timeout">
        </el-statistic>
      </el-col>
    </el-row>

    <el-progress style="padding-top: 20px; padding-bottom: 20px" :percentage="progress" :stroke-width="3" :show-text="false" />

    <p style="text-align: left;">最新解析</p>
    <div class="table-container">
      <el-table :data="list_link_src_new" style="width: 100%">
        <el-table-column prop="id" label="序号" :width="isMobile?'':80"/>
        <el-table-column prop="timestamp" label="日期" :width="isMobile?'':200" />
        <el-table-column prop="botcode" label="机器码" :width="isMobile?'':300"/>
        <el-table-column prop="link" label="链接" :width="isMobile?'':350"/>
        <el-table-column prop="text" label="内容"/>
      </el-table>
    </div>

    <p style="text-align: left;">错误日志</p>
    <div class="table-container">
      <el-table :data="list_log_error" style="width: 100%">
        <el-table-column prop="id" label="序号" :width="isMobile?'':80" />
        <el-table-column prop="timestamp" label="日期" :width="isMobile?'':200" />
        <el-table-column prop="info" label="信息"/>
        <el-table-column prop="details" label="详细"/>
      </el-table>
    </div>

  </div>
</template>

<script setup>
import { useTransition } from '@vueuse/core'
import {onMounted, ref} from "vue";
import {ElNotification} from "element-plus";
import api from "@/api";

const count_total = ref(0)
const count_read_src_done = ref(0)
const count_read_src_processing = ref(0)
const count_read_src_timeout = ref(0)
const output_count_total = useTransition(count_total, { duration: 1500 })
const output_count_read_src_done = useTransition(count_read_src_done, { duration: 1500 })
const output_count_read_src_processing = useTransition(count_read_src_processing, { duration: 1500 })
const output_count_read_src_timeout = useTransition(count_read_src_timeout, { duration: 1500 })
const list_log_error = ref([])
const list_link_src_new = ref([])
const totalSeconds = 30;
const progress = ref(100);
const isMobile = ref(false);

onMounted(() => {
  checkIsMobile()
  getData()
})

let timer = null;
const startTimer = () => {
  progress.value = 100; // 重置进度条
  timer = setInterval(() => {
    progress.value -= 100 / totalSeconds;
    if (progress.value <= 0) {
      clearInterval(timer);
      getData();
      startTimer(); // 重新启动计时器
    }
  }, 1000);
};
startTimer(); // 初始启动计时器

const checkIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

const getData = () => {
  api.post('/monitor', {
  })
    .then(response => {
      console.log(response.data)
      count_total.value = response.data.count_total
      count_read_src_done.value = response.data.count_read_src_done
      count_read_src_processing.value = response.data.count_read_src_processing
      count_read_src_timeout.value = response.data.count_read_src_timeout
      list_log_error.value = response.data.log_error
      list_link_src_new.value = response.data.link_src_new
    }).catch(err=>{
    ElNotification({
      message: err,
      type: 'error',
      duration: 10000,
    });
  })
}
</script>

<style scoped>

.flex-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px); /* 设置整个容器的高度为视口高度 */
}

.stats-row {
  flex: 0 0 auto; /* 统计信息部分不伸缩，保持原始大小 */
}

.table-container {
  flex: 1; /* 表格部分平分剩余空间 */
  height: 0; /* 防止溢出 */
  overflow: auto; /* 添加滚动条 */
}

</style>