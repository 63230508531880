import axios from 'axios';
import {ElNotification} from "element-plus";

const api = axios.create({
    baseURL: 'https://api.st.link/aiagent',
});

api.interceptors.request.use(config => {
    // 在发送请求之前动态获取token并设置到请求头
    const token = localStorage.getItem('token_aiagent');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            console.error(error)
            ElNotification({
                message: error.response.data,
                type: 'warning'
            });
        }
        return Promise.reject(error);
    }
);


export default api;
