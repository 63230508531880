<template>
  <div class="flex-container">
    <div class="flex-item-left">
      <FormTrade :SYMBOL="symbol"></FormTrade>
    </div>
    <div class="flex-item-right">
      <formNews :ID="ID"></formNews>
    </div>
  </div>
</template>

<script setup>
import FormNews from "@/components/formNews.vue";
import FormTrade from "@/components/formTrade.vue";
import api from "@/api";
import {ElNotification} from "element-plus";
import {ref} from "vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  ID: {
    type: String,
    default: '',
  }
})

const symbol = ref('')

const info = () => {
  api.post('/info', {
    'ID':props.ID
  })
    .then(response => {
      console.log('info', response.data)
      symbol.value = response.data
    }).catch(err=>{
    ElNotification({
      message: err,
      type: 'error',
      duration: 10000,
    });
  })
}

info()

</script>

<style scoped>
.flex-container {
  display: flex;
  height: 98vh;
  flex-direction: row;
}

.flex-item-left {
  flex: 1; /* 占据剩余空间 */
  overflow-y: auto; /* 内容超出时显示滚动条 */
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-item-right {
  width: 30%; /* 占据父容器宽度的 30% */
  overflow-y: auto; /* 内容超出时显示滚动条 */
  max-height: 100vh;
  padding-right: 10px;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .flex-item-left {
    display: none; /* 隐藏左边分栏 */
  }

  .flex-item-right {
    width: 100%; /* 右边分栏宽度为100% */
    padding-right: 15px; /* 可以根据实际情况去掉右侧内边距 */
    max-height: none; /* 如果需要，可以让右侧容器高度自适应 */
  }
}

</style>
