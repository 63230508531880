import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from "@/components/viewDashboard.vue";
import Stock from "@/components/viewStock.vue";
import viewTradeAndNews from '@/components/viewTradeAndNews.vue'
import viewZhongYan from '@/components/viewZhongYan.vue'

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
        },
        {
            path: '/zhongyan',
            name: 'zhongyan',
            component: viewZhongYan,
        },
        {
            path: '/stock',
            name: 'stock',
            component: Stock,
        },
        {
            path: '/stock/:ID',
            name: 'trade',
            component: viewTradeAndNews,
            props: (route) => ({ ID: route.params.ID }),
        },
    ]
});

export default router;
