<template>
  <el-timeline v-if="!loading">
    <el-timeline-item
      center
      v-for="item in news"
      :key="item.id"
      :timestamp="item.timestamp"
      placement="top"
      class="timeline-align-left">
      <el-card shadow="hover">
        <p style="font-size: 10px">{{ item.name }}</p>
        <h4 v-html="removeEmTags(item.text)"></h4>
        <h5>{{ item.link_src }}</h5>
        <a :href="item.link" target="_blank" style="font-size: 10px;color: #2c3e50"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11 17H7q-2.075 0-3.537-1.463T2 12q0-2.075 1.463-3.537T7 7h4v2H7q-1.25 0-2.125.875T4 12q0 1.25.875 2.125T7 15h4zm-3-4v-2h8v2zm5 4v-2h4q1.25 0 2.125-.875T20 12q0-1.25-.875-2.125T17 9h-4V7h4q2.075 0 3.538 1.463T22 12q0 2.075-1.463 3.538T17 17z"/></svg></a>
      </el-card>
    </el-timeline-item>
  </el-timeline>
  <div class="loading-container" v-if="loading">
    <div v-loading="loading"></div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import api from "@/api";
import {ElNotification} from "element-plus";

// eslint-disable-next-line no-undef
const props = defineProps({
  ID: {
    type: String,
    default: '',
  },
})

const loading = ref(true)
const news = ref([])

const countdownTime = 30; // 倒计时时间，以秒为单位
const countdownInterval = ref(null);
const countdownValue = ref(countdownTime);

onMounted(()=>{
  stock_news()
  startCountdown();
})

onUnmounted(() => {
  if (countdownInterval.value !== null) {
    clearInterval(countdownInterval.value);
  }
  document.title = props.ID;
});

const startCountdown = () => {
  // 清除现有的倒计时（如果存在）
  if (countdownInterval.value !== null) {
    clearInterval(countdownInterval.value);
  }

  // 重置倒计时
  countdownValue.value = countdownTime;
  updateTitle(countdownValue.value);

  // 设置新的倒计时
  countdownInterval.value = setInterval(() => {
    countdownValue.value--;
    updateTitle(countdownValue.value);

    // 当倒计时结束时
    if (countdownValue.value <= 0) {
      clearInterval(countdownInterval.value);
      stock_news(); // 调用 stock_news 函数

      // 重启倒计时
      startCountdown();
    }
  }, 1000);
};

const removeEmTags = (text) => {
  return text.replace(/<\/?em>/g, '');
};

const updateTitle = (timeLeft) => {
  document.title = `${props.ID} - ${timeLeft}`;
};

const stock_news = () => {
  api.post('/news', {
    'ID': props.ID
  })
    .then(response => {
      news.value = response.data.news
    }).catch(err=>{
    ElNotification({
      message: err,
      type: 'error',
      duration: 10000,
    })
  }).finally(()=>{
    loading.value = false
  })
}

</script>

<style scoped>
.el-timeline {
  padding-left: 15px;
}

.timeline-align-left {
  text-align: left;
}

.loading-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 95vh; /* 容器高度设置为视窗的高度，确保在整个屏幕内居中 */
}

</style>