<template>
  <div class="tradingview-widget-container" style="height:100%; width:100%">
    <div class="tradingview-widget-container__widget" style="height:calc(100%); width:100%"></div>
  </div>
</template>

<script setup>
import {onMounted, watch} from 'vue';
// eslint-disable-next-line no-undef
const props = defineProps({
  SYMBOL: {
    type: String,
    default: '',
  }
})

onMounted(() => {
});

watch(() => props.SYMBOL, (newValue) => {
  if (newValue !== '') {
    console.log('Symbol:', newValue)
    loadTradingViewScript();
  }
});

function loadTradingViewScript() {
  const script = document.createElement('script');
  script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
  script.async = true;
  script.innerHTML = JSON.stringify({
    "autosize": true,
    "symbol": props.SYMBOL,
    "interval": "60",
    "timezone": "Asia/Shanghai",
    "theme": "light",
    "style": "1",
    "locale": "zh_CN",
    "enable_publishing": false,
    "allow_symbol_change": true,
    "support_host": "https://www.tradingview.com"
  });

  document.querySelector('.tradingview-widget-container__widget').appendChild(script);
}
</script>

<style scoped>

</style>
